import { Box, Button, Center, Flex, Heading, Stack, Text } from "@chakra-ui/react"
import { Link } from "react-router-dom"

export const WindowsBuilds = () => {

    const builds = [
        {
            version: "0.3.2",
            date: "30. 3. 2023",
            link: "DungeonOfDarkness_Windows_0.3.2.zip"
        },
        {
            version: "0.3.1",
            date: "28. 3. 2023",
            link: "DungeonOfDarkness_Windows_0.3.1.zip"
        },
        {
            version: "0.3",
            date: "26. 3. 2023",
            link: "DungeonOfDarkness_Windows_0.3.zip"
        },
        {
            version: "0.2",
            date: "26. 2. 2023",
            link: "DungeonOfDarkness_Windows_0.2.zip"
        },
        {
            version: "0.1.3",
            date: "01. 2. 2023",
            link: "DungeonOfDarkness_Windows_0.1.3.zip"
        },
        {
            version: "0.1.2",
            date: "30. 1. 2023",
            link: "DungeonOfDarkness_Windows_0.1.2.zip"
        },
        {
            version: "0.1",
            date: "29. 1. 2023",
            link: "DungeonOfDarkness_Windows_0.1.zip"
        }
    ]

    return <Stack>

        <Box position="absolute" left="1rem" top="1rem">
            <Link to="/maturita">
                <Button>Zpět</Button>
            </Link>
        </Box>

        <Center><Heading>Windows</Heading></Center>

        {
            builds.map((build) => <Flex w="50vw" justifyContent="space-between" alignItems="center">
                <Text>Dungeon of Darkness v{build.version} ({build.date})</Text>
                <a download href={`/gameBuilds/${build.link}`}><Button>Download</Button></a>
            </Flex>)
        }
        
    </Stack>
}
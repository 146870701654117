import { CheckIcon, DeleteIcon } from "@chakra-ui/icons"
import { Box, Button, Center, Flex, FormControl, FormLabel, Heading, Input, Modal, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Text, useDisclosure } from "@chakra-ui/react"
import { deleteDoc, doc, updateDoc } from "firebase/firestore"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useUserContext } from "../Components/UserContext"
import { GET_ALL_FEEDBACKS } from "../Feedbacks"
import { db } from "../firebase"

type Feedback = {
    id: string,
    name: string,
    text: string,
    date: Date,
    isDone: boolean
}

export const FeedbackList = () => {

    const { isLogged, login } = useUserContext();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    
    const [reload, setReload] = useState(false);
    const [typeRemove, setTypeRemove] = useState(false);

    const [feedbacks, setFeedbacks] = useState<any>(null);
    const [feedbackId, setFeedbackId] = useState("");
    
    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        const fetch = async () => {
            setFeedbacks(await GET_ALL_FEEDBACKS());
        }
        fetch();
    }, [reload])

    if(!isLogged) {
        return <Box w="400px">

        <Box position="absolute" left="1rem" top="1rem">
            <Link to="/maturita">
                <Button>Zpět</Button>
            </Link>
            <Link to="/maturita/bugs">
                <Button ml="1rem">Bugy</Button>
            </Link>
        </Box>

            <FormControl>
                <FormLabel>Email</FormLabel>
                <Input type='email' value={email} onChange={(e) => setEmail(e.target.value)} />
            </FormControl>
            <FormControl>
                <FormLabel>Heslo</FormLabel>
                <Input type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
            </FormControl>
            <Center>
                <Button mt="1rem" onClick={() => login(email, password)}>Přihlásit se</Button>
            </Center>
        </Box>
    }

    if(!feedbacks) {
        return <Spinner />
    }

    const openModal = (id: string) => {
        setFeedbackId(id);
        onOpen();
    }

    const removeFeedback = async () => {
        onClose();
        await deleteDoc(doc(db, "feedbacks", feedbackId));

        setReload(!reload);
    }

    const feedbackIsDone = async () => {
        onClose();
        const ref = doc(db, "feedbacks", feedbackId);

        await updateDoc(ref, {
          isDone: true
        });

        setReload(!reload);
    }

    return <Flex>

        <Box position="absolute" left="1rem" top="1rem">
            <Link to="/maturita">
                <Button>Zpět</Button>
            </Link>
            <Link to="/maturita/bugs">
                <Button ml="1rem">Bugy</Button>
            </Link>
        </Box>

        {
            feedbacks?.map((feedback: Feedback) => !feedback.isDone ? <Box key={`feedback${feedback.id}`} maxW="20vw" border="solid 1px black" p="1rem" m="1rem">
                <DeleteIcon color="red.800" cursor="pointer" onClick={() => {
                    setTypeRemove(true);
                    openModal(feedback.id);
                }} />
                <CheckIcon ml="1rem" cursor="pointer" onClick={() => {
                    setTypeRemove(false);
                    openModal(feedback.id);
                }} />
                <Heading>{feedback.name}</Heading>
                <Text>{feedback.text}</Text>
            </Box> : '')
        }

        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
            <ModalHeader>{typeRemove ? "Odstranit feedback" : "Feedback je splněn"}</ModalHeader>
            <ModalCloseButton />

            <ModalFooter>
                <Button variant='ghost' mr={3} onClick={onClose}>Zrušit</Button>
                <Button colorScheme='blue' onClick={typeRemove ? removeFeedback : feedbackIsDone}>{typeRemove ? "Ostranit" : "Splněno"}</Button>
            </ModalFooter>
            </ModalContent>
        </Modal>
    </Flex>
}
import { HamburgerIcon } from "@chakra-ui/icons"
import { Box, IconButton, Menu, MenuButton, MenuIcon, MenuItem, MenuList, Spinner } from "@chakra-ui/react"
import { Link } from "react-router-dom"

export const HomePage = () => {
    return <Box>
        <Box position="absolute" left="1rem" top="1rem">
            <Menu>
                <MenuButton as={IconButton} aria-label="Options" icon={<HamburgerIcon />} variant="outline" />
                <MenuList>
                    <Link to="/maturita"><MenuItem>Maturita</MenuItem></Link>
                </MenuList>
            </Menu>
        </Box>
        <Spinner />
    </Box>
}
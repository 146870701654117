import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Box, Button, Center, FormControl, FormHelperText, FormLabel, Heading, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Radio, RadioGroup, Spinner, Stack, Text, Textarea, useDisclosure, useToast } from "@chakra-ui/react"
import { useState } from "react"
import { Link } from "react-router-dom";
import { db } from "../firebase";
import { v4 as uuid } from "uuid";
import { doc, setDoc } from "firebase/firestore";

export const ReportBug = () => {

    const [name, setName] = useState("");
    const [distro, setDistro] = useState("windows");
    const [bug, setBug] = useState("");

    const [nameWarning, setNameWarning] = useState("");
    const [bugWarning, setBugWarning] = useState("");

    const [uploading, setUploading] = useState(false);

    const { isOpen, onOpen, onClose } = useDisclosure();

    const toast = useToast();

    const reportBug = async () => {
        let canSend = true;
        
        if(name.length === 0) {
            setNameWarning("Prosím o vyplnění jména");
            canSend = false;
        } else {
            setNameWarning("");
        }

        if(bug.length === 0) {
            setBugWarning("Prosím o vyplnění popisu bugu");
            canSend = false;
        } else {
            setBugWarning("");
        }

        onClose();

        
        if(canSend){

            setUploading(true);
            await setDoc(doc(db, "bugs", uuid()), {
                name,
                distro,
                description: bug,
                date: new Date(),
                isDone: false
            });
            setUploading(false);

            toast({
                title: 'Bug odeslán.',
                description: "Děkuji za nahlášení bugu ve hře.",
                status: 'success',
                duration: 5000,
                isClosable: true,
            })
        } else {
            toast({
                title: 'Bug nebyl odeslán.',
                description: "Prosím o vyplnění všech údajů.",
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        }
    }

    if(uploading){
        return <Spinner />
    }

    return <>
        <Box position="absolute" left="1rem" top="1rem">
            <Link to="/maturita">
                <Button>
                    <ChevronLeftIcon fontSize="lg" />
                    <Text>Zpět</Text>
                </Button>
            </Link>
        </Box>
        <Stack w="50vw">
            <Center><Heading>Nahlásit BUG</Heading></Center>
            <FormControl>
                <FormLabel>Jméno</FormLabel>
                <Input type='text' value={name} onChange={(e) => {
                    setName(e.target.value);
                    /*if(name.length == 0) {
                        setNameWarning("Prosím o vyplnění jméno pro případnou diskuzi")
                    } else {
                        setNameWarning("")
                    }*/
                }} />
                <FormHelperText>Prosím o zadání reálného jméno pro případnou diskuzi.</FormHelperText>
                <FormHelperText color="red.800">{nameWarning}</FormHelperText>
            </FormControl>
            <FormControl as='fieldset'>
                <FormLabel as='legend'>Distribuce hry</FormLabel>
                <RadioGroup defaultValue='windows'>
                    <HStack spacing='24px'>
                        <Radio value='windows' onChange={(e) => setDistro(e.target.value)}>Windows</Radio>
                        <Radio value='linux' onChange={(e) => setDistro(e.target.value)}>Linux</Radio>
                    </HStack>
                </RadioGroup>
            </FormControl>
            <FormControl>
                <FormLabel>Popis Bugu</FormLabel>
                <Textarea value={bug} onChange={(e) => setBug(e.target.value)} />
                <FormHelperText>Zde prosím o přesné popsání bugu</FormHelperText>
                <FormHelperText color="red.800">{bugWarning}</FormHelperText>
            </FormControl>
            <Center>
                <Button w="150px" onClick={onOpen}>Nahlásit Bug</Button>
            </Center>
        </Stack>



        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
            <ModalHeader>Potvrzení o bugu</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                Dle svého největšího vědomí a svědomí potvrzuji, že to netrollím.
            </ModalBody>

            <ModalFooter>
                <Button variant='ghost' mr={3} onClick={onClose}>Neodesílat</Button>
                <Button colorScheme='blue' onClick={reportBug}>Odeslat</Button>
            </ModalFooter>
            </ModalContent>
        </Modal>
    </>
}

function collection(db: any, arg1: string) {
    throw new Error("Function not implemented.");
}

import { Box, Link } from "@chakra-ui/react"

export const Background = () => {
    return <>
        <Box w="100vw" h="100vh" zIndex="-3" position="absolute" left="0" top="0" background="url(grass.png)"></Box>
            <Box position="absolute" zIndex="-2">
                <video autoPlay loop muted playsInline style={{backgroundColor: "gray", zIndex: "-2"}}>
                    <source src='/background.mp4' type='video/mp4' />
                </video>
            </Box>
        <Box w="100vw" h="100vh" zIndex="-1" position="absolute" left="0" top="0" backgroundColor="black" opacity="0.7"></Box>
        <Box position="absolute" mb="1rem" bottom="0" color="whiteAlpha.800">&copy; Martin Bíško 2023, <Link href="https://yarynator.dev" target="_blank">yarynator.dev</Link></Box>
    </>
}
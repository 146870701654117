import { CheckIcon, DeleteIcon } from "@chakra-ui/icons";
import { Box, Button, Center, Flex, FormControl, FormLabel, Heading, Input, Modal, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GET_ALL_BUGS } from "../Bugs";
import { useUserContext } from "../Components/UserContext"
import { db } from "../firebase";

type Bug = {
    id: string,
    name: string,
    distro: string,
    description: string,
    date: Date,
    isDone: boolean
}

export const Bugs = () => {
    
    const { isLogged, login } = useUserContext();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [bugs, setBugs] = useState<any>(null);
    const [typeRemove, setTypeRemove] = useState(false);

    const [reload, setReload] = useState(false);
    const [bugId, setBugId] = useState("");
    
    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        const fetch = async () => {
            setBugs(await GET_ALL_BUGS());
        }
        fetch();
    }, [reload])

    if(!isLogged) {
        return <Box w="400px">

        <Box position="absolute" left="1rem" top="1rem">
            <Link to="/maturita">
                <Button>Zpět</Button>
            </Link>
            <Link to="/maturita/feedback-list">
                <Button ml="1rem">Nápady/Zpětné vazby</Button>
            </Link>
        </Box>

            <FormControl>
                <FormLabel>Email</FormLabel>
                <Input type='email' value={email} onChange={(e) => setEmail(e.target.value)} />
            </FormControl>
            <FormControl>
                <FormLabel>Heslo</FormLabel>
                <Input type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
            </FormControl>
            <Center>
                <Button mt="1rem" onClick={() => login(email, password)}>Přihlásit se</Button>
            </Center>
        </Box>
    }

    const openModal = (id: string) => {
        onOpen();
        setBugId(id);
    }

    const removeBug = async () => {

        await deleteDoc(doc(db, "bugs", bugId));

        setReload(!reload);
        onClose();
    }

    const bugIsDone = async () => {
        onClose();
        const ref = doc(db, "bugs", bugId);

        await updateDoc(ref, {
          isDone: true
        });

        setReload(!reload);
    }

    return <Flex wrap="wrap">

        <Box position="absolute" left="1rem" top="1rem">
            <Link to="/maturita">
                <Button>Zpět</Button>
            </Link>
            <Link to="/maturita/feedback-list">
                <Button ml="1rem">Nápady/Zpětné vazby</Button>
            </Link>
        </Box>

        {
            bugs?.map((bug: Bug) => !bug.isDone ? <Box key={`bug${bug.id}`} p="1rem" border="solid 1px black" m="1rem" >
                <Box position="relative" cursor="pointer">
                    <DeleteIcon color="red.800" onClick={() => {
                        setTypeRemove(true);
                        openModal(bug.id);
                    }} />
                    <CheckIcon ml="1rem" cursor="pointer" onClick={() => {
                        setTypeRemove(false);
                        openModal(bug.id);
                    }} />
                </Box>
                <Heading>{bug.name}</Heading>
                <Text fontSize="sm">{bug.distro}</Text>
                <Text>{bug.description}</Text>
            </Box> : "")
        }

        {
            bugs?.length === 0 ? <Heading>Žádný bug</Heading> : ""
        }


        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
            <ModalHeader>{typeRemove ? "Smazat bug" : "Bug byl vyřešen"}</ModalHeader>
            <ModalCloseButton />

            <ModalFooter>
                <Button variant='ghost' mr={3} onClick={onClose}>Zrušit</Button>
                <Button colorScheme='blue' onClick={typeRemove ? removeBug : bugIsDone}>{typeRemove ? "Ostranit" : "Vyřešeno"}</Button>
            </ModalFooter>
            </ModalContent>
        </Modal>
    </Flex>
}
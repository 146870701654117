import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyASA_EpTrB48g4FK12EcZ1mDLSg9o9zJv0",
  authDomain: "maturitni-projekt.firebaseapp.com",
  projectId: "maturitni-projekt",
  storageBucket: "maturitni-projekt.appspot.com",
  messagingSenderId: "499360784856",
  appId: "1:499360784856:web:329700051e4652839712ad"
};


export const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
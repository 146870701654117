import { Box, Center, Heading, Stack, Text } from "@chakra-ui/react"
import { Background } from "../Components/Background"
import { Buttons } from "../Components/Buttons"

export const Maturita = () => {
    return <>
        <Background />
        <Stack spacing="10vh">
            <Box>
                <Heading color="white" textAlign="center">Maturitní projekt - Martin Bíško</Heading>
                <Text mt="1rem" color="whiteAlpha.800" textAlign="center">Vedoucí projektu: Mgr. Jan Mottl</Text>
            </Box>
            <Buttons />
        </Stack>
    </>
}
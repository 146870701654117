import { Button, Center, Flex, Link as ChakraLink, Stack, Text, Tooltip } from "@chakra-ui/react"
import { AiFillWindows } from "react-icons/ai";
import { VscTerminalLinux } from "react-icons/vsc";
import { Link } from "react-router-dom";

export const Buttons = () => {
    return <Center>
        <Stack>
            <Flex wrap="wrap" justifyContent="space-around">
                {/*<Link to="/maturita/windows-builds">*/}
                <ChakraLink download href="gameBuilds/DungeonOfDarkness_Windows_0.3.2.zip">
                    <Button m=".5rem">
                        <AiFillWindows /> 
                        <Text ml=".5rem">Download</Text>
                    </Button>
                </ChakraLink>
                {/*</Link>
                <Link to="/maturita/linux-builds">*/}
                <ChakraLink download href="gameBuilds/DungeonOfDarkness_Linux_0.3.2.zip">
                    <Button m=".5rem">
                        <VscTerminalLinux /> 
                        <Text ml=".5rem">Download</Text>
                    </Button>
                </ChakraLink>
                {/*</Link>*/}
                <ChakraLink download href="./Dokumentace/Dokumentace.pdf">
                    <Button m=".5rem">
                        <Text>Dokumentace (PDF)</Text>
                    </Button>
                </ChakraLink>
                <ChakraLink download href="./Dokumentace/Dokumentace.zip">
                    <Button m=".5rem">
                        <Text>Dokumentace (LaTeX)</Text>
                    </Button>
                </ChakraLink>
                <ChakraLink href="https://github.com/Yarynator/maturitni-projekt" target="_blank">
                    <Button m=".5rem">
                        <Text>Github projekt</Text>
                    </Button>
                </ChakraLink>
            </Flex>
            <Flex wrap="wrap" justifyContent="space-around" alignItems="center">
                <Button mt="1rem">
                    <Link to="/maturita/report-bug">
                        <Button w="150px">
                            Nahlásit BUG
                        </Button>
                    </Link>
                </Button>
                <Button mt="1rem">
                    <Link to="/maturita/feedback">
                        <Button w="200px" ml="1rem">
                            Nápady/Zpětná vazba
                        </Button>
                    </Link>
                </Button>
            </Flex>
        </Stack>
    </Center>
}
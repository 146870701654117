import { createContext, useContext, useState } from "react";
import { getAuth, signInWithEmailAndPassword, User as FirebaseUser } from "firebase/auth";
import { app } from "../firebase";

export const UserContext = createContext({
    loading: true,
    isLogged: false,
    user: null,
    login: (email: string, password: string) => {},
    logout: () => {},
})

export const UserContextProvider = (props: any) => {
    const [isLogged, setIsLogged] = useState(false);
    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState<any>(null);
    let auth = getAuth(app);

    const login = (email: string, password: string) => {
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            setUser(userCredential.user);
            
            setIsLogged(true);
        })
    }

    const logout = () => {
        setUser(null);
        setIsLogged(false);
    }

    return <>
        <UserContext.Provider value={{
            loading,
            isLogged,
            user,
            login,
            logout
        }}>
            {props.children}
        </UserContext.Provider>
    </>
}
export const useUserContext = () => {
    return useContext(UserContext);
}
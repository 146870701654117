import { collection, getDocs, query } from "firebase/firestore";
import { db } from "./firebase";

export const GET_ALL_BUGS = async () => {

    const ContactsArray : Array<any> = [];

    const q = query(collection(db, "bugs"));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async (doc) => {

        ContactsArray.push({
            id: doc.id,
            name: doc.data().name,
            distro: doc.data().distro,
            description: doc.data().description,
            date: doc.data().date,
            isDone: doc.data().isDone,
        })
    })

    ContactsArray.sort((a: any, b: any) => {
        return a.date - b.date;
    })

    return ContactsArray;
}
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Box, Button, Center, FormControl, FormHelperText, FormLabel, Heading, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Text, Textarea, useDisclosure, useToast } from "@chakra-ui/react";
import { doc, setDoc } from "firebase/firestore";
import { useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../firebase";
import { v4 as uuid } from "uuid";

export const SendFeedback = () => {

    const [name, setName] = useState("")
    const [text, setText] = useState("")

    const [textWarning, setTextWarning] = useState("");

    const [uploading, setUploading] = useState(false);
    
    const { isOpen, onOpen, onClose } = useDisclosure();
    
    const toast = useToast();

    const sendFeedback = async () => {
        let canSend = true;

        if(text.length === 0) {
            setTextWarning("Obsah nápadu/zpětné vazby musí být vyplněn!");
            canSend = false;
        } else {
            setTextWarning("");
        }

        if(canSend){

            setUploading(true);
            await setDoc(doc(db, "feedbacks", uuid()), {
                name,
                text,
                date: new Date(),
                isDone: false
            });
            setUploading(false);

            toast({
                title: 'Nápad/zpětná vazba odeslána.',
                description: "Děkuji za výpomoc.",
                status: 'success',
                duration: 5000,
                isClosable: true,
            })
        } else {
            toast({
                title: 'Nápad/zpětná vazba nebyla odeslána.',
                description: "Prosím o vyplnění všech údajů.",
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        }

        onClose();
    }

    if(uploading) {
        return <Spinner />
    }
    
    return <Box>
        <Box position="absolute" left="1rem" top="1rem">
            <Link to="/maturita">
                <Button>
                    <ChevronLeftIcon fontSize="lg" />
                    <Text>Zpět</Text>
                </Button>
            </Link>
        </Box>
        
        <Center>
            <Heading>Nápady/Zpětná vazba</Heading>
        </Center>

        <Box w="50vw">
            <FormControl>
                <FormLabel>Jméno (nepovinné)</FormLabel>
                <Input type='text' value={name} onChange={(e) => setName(e.target.value)} />
            </FormControl>
            <FormControl>
                <FormLabel>Obsah nápadu/zpětné vazby</FormLabel>
                <Textarea value={text} onChange={(e) => setText(e.target.value)} />
                <FormHelperText color="red.800">{textWarning}</FormHelperText>
            </FormControl>
            <Center>
                <Button w="150px" onClick={onOpen}>Odeslat</Button>
            </Center>
        </Box>

        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
            <ModalHeader>Potvrzení o odeslání</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                Dle svého největšího vědomí a svědomí potvrzuji, že to netrollím.
            </ModalBody>

            <ModalFooter>
                <Button variant='ghost' mr={3} onClick={onClose}>Neodesílat</Button>
                <Button colorScheme='blue' onClick={sendFeedback}>Odeslat</Button>
            </ModalFooter>
            </ModalContent>
        </Modal>
    </Box>
}
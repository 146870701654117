import { ChakraProvider, Center } from '@chakra-ui/react';
import { Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { UserContextProvider } from './Components/UserContext';
import { Page404 } from './Pages/404';
import { Bugs } from './Pages/Bugs';
import { FeedbackList } from './Pages/FeedbackList';
import { HomePage } from './Pages/HomePage';
import { LinuxBuilds } from './Pages/LinuxBuilds';
import { Maturita } from './Pages/Maturita';
import { ReportBug } from './Pages/ReportBug';
import { SendFeedback } from './Pages/SendFeedback';
import { WindowsBuilds } from './Pages/WindowsBuilds';

function App() {

    return <ChakraProvider>
        <UserContextProvider>
            <BrowserRouter>
                <Center minH="100vh">
                    <Routes>
                        <Route path='/' element={<HomePage />} />
                        <Route path='/maturita' element={<Maturita />} />
                        <Route path='/maturita/windows-builds' element={<WindowsBuilds />} />
                        <Route path='/maturita/linux-builds' element={<LinuxBuilds />} />
                        <Route path='/maturita/report-bug' element={<ReportBug />} />
                        <Route path='/maturita/bugs' element={<Bugs />} />
                        <Route path='/maturita/feedback' element={<SendFeedback />} />
                        <Route path='/maturita/feedback-list' element={<FeedbackList />} />
                        <Route path='/*' element={<Page404 />} />
                    </Routes>
                </Center>
            </BrowserRouter>
        </UserContextProvider>
    </ChakraProvider>
}

export default App;

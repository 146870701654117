import { Box, Button, Center, Heading, Stack } from "@chakra-ui/react"
import { Link } from "react-router-dom"

export const Page404 = () => {
    return <Box>
        <Heading>404: Page not found</Heading>
        <Stack>
            <Center><Link to="/"><Button mt="1rem">Zpět na hlavní stranu</Button></Link></Center>
            <Center><Link to="/maturita"><Button mt="1rem">Maturitní projekt</Button></Link></Center>
        </Stack>
    </Box>
}